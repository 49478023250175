import React, { useEffect, useState } from 'react';

export const getPermissions = async () => {
    if (!navigator.mediaDevices) {
        navigator.mediaDevices = {};
    }

    if (!navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia = function (constraints) {
            const getUserMedia =
                navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

            if (!getUserMedia) {
                return Promise.reject(
                    new Error("getUserMedia is not implemented in this browser")
                );
            }

            return new Promise((resolve, reject) => {
                getUserMedia.call(navigator, constraints, resolve, reject);
            });
        };
    }

    try {
        const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        return mediaStream; // Return the mediaStream
    } catch (err) {
        throw err; // Throw the error to be handled elsewhere
    }
};
