import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../../AuthContext';
import Card from '@mui/material/Card';
import { Box, Switch, TextField, Typography } from '@mui/material';
import { Button } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./AuctionLogin.css";

function AuctionLogin() {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);
  const [access_token, setAccessToken] = useState(localStorage.getItem('access_token'));

  // Check for remember me credentials in sessionStorage
  useEffect(() => {
    const savedCredentials = JSON.parse(sessionStorage.getItem('rememberMeCredentials'));
    if (savedCredentials) {
      formik.setValues(savedCredentials);
      setRememberMe(true);
    }
  }, []);

  // formik initialization
  const formik = useFormik({
    initialValues: {
      email_or_username: "",
      password: "",
    },
    validationSchema: Yup.object({
      email_or_username: Yup.string().required("Required"),
      password: Yup.string()
        .min(4, "Password must be at least 4 characters")
        .required("Required"),
    }),
    onSubmit: async (values) => {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, values);

        if (response.status === 200) {
          const userData = response.data["0"];
          const accessToken = response.data.access_token;

          // Save access token and user details in localStorage
          localStorage.setItem('access_token', accessToken);
          localStorage.setItem('email', userData.email);
          localStorage.setItem('hash_id', userData.hash_id);
          localStorage.setItem('username', userData.username);

          // Process roles and store them
          const roles = userData.roles.map(role => role.name);
          localStorage.setItem('roles', roles.join(','));

          // Update access_token in state
          setAccessToken(accessToken);

          // Call login function with roles
          login(roles.join(','));

          // Save credentials in sessionStorage if 'remember me' is checked
          if (rememberMe) {
            sessionStorage.setItem('rememberMeCredentials', JSON.stringify(values));
          } else {
            sessionStorage.removeItem('rememberMeCredentials');
          }

          // Navigate based on user role
          if (roles.includes('admin')) {
            navigate('/super/admin/dashboard');
          } else if (roles.includes('auctioneer')) {
            navigate('/auctioner/dashboard');
          } else if (roles.includes('buyer')) {
            navigate('/user/dashboard');
          } else {
            navigate('/');
          }

          toast.success("Login successful!");

        } else if (response.data.status === "error" && response.data.message === "invalid-credentials") {
          toast.error("Invalid credentials, please try again.");
        } else {
          console.error('Login failed:', response.data);
          toast.error('Login failed. Please try again.');
        }
      } catch (error) {
        toast.error('An error occurred during login.');
        console.error('Login error:', error);
      }
    }
  });

  // Handle Remember Me checkbox toggle
  const handleSetRememberMe = () => setRememberMe(prevState => !prevState);

  // If user is already logged in, redirect to a dashboard or homepage
  if (access_token) {
    navigate('/'); // Adjust this route as needed
    return null;
  }




  return (
    <Card sx={{ maxWidth: 400, mx: "auto", mt: 8, p: 3 }}>
      <ToastContainer />
      <Box sx={{ mb: 3, textAlign: "center" }}>
        <Typography variant="h4" color="textPrimary" gutterBottom>
          Sign in
        </Typography>
      </Box>
      <Box component="form" onSubmit={formik.handleSubmit}>
        <Box sx={{ mb: 2 }}>
          <TextField
            fullWidth
            label="Email or Username"
            name="email_or_username"
            value={formik.values.email_or_username}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email_or_username && Boolean(formik.errors.email_or_username)}
            helperText={formik.touched.email_or_username && formik.errors.email_or_username}
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <TextField
            fullWidth
            label="Password"
            type="password"
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <Switch checked={rememberMe} onChange={handleSetRememberMe} />
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{ ml: 1, cursor: "pointer" }}
            // onClick={handleSetRememberMe}
          >
            Remember me
          </Typography>
        </Box>
        <Box sx={{ mb: 2 }}>
          <div className='text-center'>
            <Button variant="contained" className='sign-in-auctioner' color="primary" fullWidth type="submit">
              Sign in
            </Button>
          </div>
        </Box>
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="body2" color="textSecondary">
            Don&apos;t have an account?{" "}
            <Link to="/authentication/sign-up">
              <Typography
                variant="body2"
                color="primary"
                sx={{ textDecoration: "none" }}
              >
                Sign up
              </Typography>
            </Link>
          </Typography>
        </Box>
      </Box>
    </Card>
  );
}
export default AuctionLogin;