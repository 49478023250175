import React, { useEffect, useState } from 'react';
import { Dropdown, Form, Button } from 'react-bootstrap';
import Logo from '../../assets/images/loggo.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import "./Header.css"

const Header2 = () => {
    const [categories, setCategories] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/categories`, {
                    headers: {
                        "ngrok-skip-browser-warning": "69420"
                    }
                });
                const { data } = response.data;
                setCategories(data);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };
        fetchCategories();
    }, []);

    const handleMilesSelect = (miles) => {
        navigate(`/lots?auctionsnearme=${miles}`);
    };

    return (
        <div className='header-two flex items-center justify-center relative'>
            <div className='main-header flex items-center justify-center'>
                <div className='container flex items-center justify-center'>
                    <div className='row'>
                        <div className='col-md-12 d-flex flex-column flex-md-row align-items-center'>
                            <div className='d-flex align-items-center items-center justify-center gap-4 py-3 header-two-mobile w-full   '>
                                <p className='login-bidder text-uppercase mb-0'>
                                    <Link to="/">
                                        <img src={Logo} alt='logo' />
                                    </Link>
                                </p>
                                <div className=' header-two-container d-flex border-[1.5px] border-blue-400 rounded-3xl p-0 '>
                                    <Dropdown className='filter-mobile border-r border-blue-400 rounded-0 h-full px-2 py-2  max-sm:border-0'>
                                        <Dropdown.Toggle variant='secondary' id='dropdown-category' className='shop-category bg-transparent border-0 font-semibold border-r-[1px] border-r border-blue-400 outline-0 font-bold mobile-view-distance shop-category-mobile' style={{ color: "#2696ff", fontWeight: "700" }}>
                                            Shop By Category
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {categories.map((category) => (
                                                <Dropdown.Item
                                                    key={category.hash_id}
                                                    as={Link}
                                                    to={`/lots?category_hash_id=${category.hash_id}`}
                                                >
                                                    {category.name}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Form className='filter-mobile w-[300px] d-inline px-2  border-x border-blue-400 rounded-0  max-sm:border-0'>
                                        <Form.Control type='text' placeholder='Search all of iLobid' className='header-searching bg-transparent text-black rounded-none border-0 border-r-[1px] border-[#2696ff] ' />
                                    </Form>
                                    <Form className='filter-mobile w-[120px] d-inline px-2 border-x border-blue-400 rounded-0  max-sm:border-0'>
                                        <Form.Control type='text' placeholder='Zip Code' className='zip-code border-0 border-blue-500 bg-transparent text-black ' />
                                    </Form>
                                    <Dropdown className='filter-mobile bg-[#e8e8ed] px-2 border-x border-blue-400 rounded-0 py-2 h-full  max-sm:border-0' onSelect={handleMilesSelect}>
                                        <Dropdown.Toggle variant='secondary' id='dropdown-miles' className='miles bg-transparent text-black border-0 outline-none mobile-view-distance'>
                                            50 Miles
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item eventKey='10'>10 Miles</Dropdown.Item>
                                            <Dropdown.Item eventKey='25'>25 Miles</Dropdown.Item>
                                            <Dropdown.Item eventKey='50'>50 Miles</Dropdown.Item>
                                            <Dropdown.Item eventKey='100'>100 Miles</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Button
                                        variant='primary'
                                        className='filter-mobile search-btn h-[57px] text-black  px-5 text-white max-sm:border-0 max-sm:rounded-0'
                                        style={{
                                            borderTopRightRadius: '1.4rem',
                                            borderBottomRightRadius: '1.4rem',
                                            borderBottomLeftRadius: '0',
                                            borderTopLeftRadius: '0',
                                        }}
                                    >
                                        Search
                                    </Button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Header2;