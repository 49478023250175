import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AuctionMainConetnt.css';
import DATA from "../../../../assets/images/Book1.png";
import LotLoader from '../Common/LotLoader';
import { useLocation, useNavigate } from 'react-router-dom';
import Magnifier from 'react-magnifier';

const headers = {
    "ngrok-skip-browser-warning": "69420",
};
const AuctionMainContent = ({ lotSidebarState }) => {
    const navigate = useNavigate();
    let filterQuery = ""
    const location = useLocation();
    const [auctionDat, setAuctionDat] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [modalShow, setModalShow] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [showDetails, setShowDetails] = useState({});
    const [showLiveButton, setShowLiveButton] = useState(true);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [buttonsVisibility, setButtonsVisibility] = useState({
        auctionDetail: true,
        registerToBid: true,
        viewCatalog: true,
        bidLive: true
    });
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const status = searchParams.get('status');
        const auctionsNearMe = searchParams.has('auctionsnearme');
        const categoryHashId = searchParams.get('category_hash_id');
        const hashId = searchParams.get('hash_id');
        let apiUrl = `${process.env.REACT_APP_API_URL}/get/auctions`;
        if (hashId) {
            apiUrl = `${process.env.REACT_APP_API_URL}/get/auctions?state=${hashId}`;
        } else if (categoryHashId) {
            apiUrl = `${process.env.REACT_APP_API_URL}/lots?category=${categoryHashId}`;
        } else if (status === 'CLOSING') {
            apiUrl = `${process.env.REACT_APP_API_URL}/get/auctions?closing=true`;
        } else if (auctionsNearMe) {
            apiUrl = `${process.env.REACT_APP_API_URL}/get/auctions?nearmylocation=50`;
        }
        axios.get(apiUrl, { headers })
            .then(response => {
                setAuctionDat(response.data.data);
                console.log(response.data.data,"auction")
                setLoading(false);
            })
            .catch(error => {
                console.error('There was an error fetching the auction data:', error);
                setLoading(false);
            });
    }, [location.search]);

    const handleRegisterClick = () => {
        navigate('/authentication/sign-in');
    };
    const isUserRegistered = localStorage.getItem('username') || localStorage.getItem('hash_id');

    const handleItemClick = (hash_id) => {
        navigate(`/auctions/${hash_id}`);
    };

    useEffect(() => {
        setIsLoading(true);
        if (lotSidebarState?.selectedAuctionStatus) {
            filterQuery += `status=${lotSidebarState.selectedAuctionStatus}`;
        }
        if (lotSidebarState?.selectedLotStatus) {
            filterQuery += `${filterQuery ? '&' : ''}filter=${lotSidebarState.selectedLotStatus}`;
        }
        if (lotSidebarState?.selectedCategory) {
            filterQuery += `${filterQuery ? '&' : ''}category=${lotSidebarState.selectedCategory}`;
        }
        if (lotSidebarState?.selectedCountry) {
            filterQuery += `${filterQuery ? '&' : ''}country=${lotSidebarState.selectedCountry}`;
        }
        if (lotSidebarState?.selectedCountryState) {
            filterQuery += `${filterQuery ? '&' : ''}countryState=${lotSidebarState.selectedCountryState}`;
        }
        if (lotSidebarState?.shippingAvailable !== "") {
            filterQuery += `${filterQuery ? '&' : ''}shippingAvailable=${lotSidebarState.shippingAvailable}`;
        }
        if (filterQuery) {
            axios.get(`${process.env.REACT_APP_API_URL}/get/auctions?${filterQuery}`, { headers })
                .then(response => {
                    setAuctionDat(response?.data?.data ?? []);
                })
                .catch(error => {
                    console.error('There was an error fetching the lots data:', error);
                    setAuctionDat([]);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            setAuctionDat([]);
            setIsLoading(false);
        }
    }, [lotSidebarState]);

    const handleImageClick = (index) => {
        setCurrentImageIndex(index);
        setModalShow(true);
    };

    const handleClose = () => setModalShow(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setShowLiveButton(prevShowLiveButton => !prevShowLiveButton);
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const totalItems = auctionDat.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const handleItemsPerPageChange = (event) => {
        setItemsPerPage(Number(event.target.value));
        setCurrentPage(1);
    };

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const paginateItems = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return auctionDat.slice(startIndex, endIndex);
    };

    const toggleDetails = (itemId) => {
        setShowDetails(prevShowDetails => ({ ...prevShowDetails, [itemId]: !prevShowDetails[itemId] }));
    };

    const generatePaginationButtons = () => {
        const buttons = [];
        const maxPageButtons = 5;
        if (totalPages <= maxPageButtons) {
            for (let i = 1; i <= totalPages; i++) buttons.push(i);
        } else {
            let startPage = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
            let endPage = Math.min(totalPages, currentPage + Math.floor(maxPageButtons / 2));
            if (currentPage - Math.floor(maxPageButtons / 2) < 1) endPage = Math.min(maxPageButtons, totalPages);
            if (currentPage + Math.floor(maxPageButtons / 2) > totalPages) startPage = Math.max(1, totalPages - maxPageButtons + 1);
            if (startPage > 1) {
                buttons.push(1);
                if (startPage > 2) buttons.push('...');
            }
            for (let i = startPage; i <= endPage; i++) {
                buttons.push(i);
            }
            if (endPage < totalPages) {
                if (endPage < totalPages - 1) buttons.push('...');
                buttons.push(totalPages);
            }
        }
        return buttons;
    };
    if (loading) {
        return <div><LotLoader /></div>;
    }
    return (
        <div className='auction-main-content'>
            <div className='container'>
                <div className='row'>
                    <div className='col d-flex justify-content-end'>
                        <div className='sort-auction'>
                            <div className='d-flex pt-2'>
                                <h5>Sort:</h5>
                                <span className='mx-2'>
                                    <select className='form-select'>
                                        <option value='best-match'>Best Match</option>
                                        <option value='distance-nearest'>Distance (Nearest)</option>
                                    </select>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row pt-3'>
                    <div className='col-md-6'>
                        <div className='d-flex'>
                            <h5>Show:</h5>
                            <span className='mx-2'>
                                <select
                                    className='form-select'
                                    value={itemsPerPage}
                                    onChange={handleItemsPerPageChange}
                                >
                                    <option value='10'>10 auctions</option>
                                    <option value='25'>25 auctions</option>
                                    <option value='50'>50 auctions</option>
                                    <option value='100'>100 auctions</option>
                                </select>
                            </span>
                        </div>
                        <div className='showing items'>
                            <p>Showing {((currentPage - 1) * itemsPerPage) + 1} to {Math.min(currentPage * itemsPerPage, totalItems)} of {totalItems} auctions</p>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='d-flex justify-content-end'>
                            <nav aria-label="Page navigation">
                                <ul className="pagination">
                                    {currentPage > 1 && (
                                        <li className="page-item">
                                            <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
                                                Previous
                                            </button>
                                        </li>
                                    )}
                                    {generatePaginationButtons().map((button, index) => (
                                        <li
                                            key={index}
                                            className={`page-item ${button === currentPage ? 'active' : ''}`}
                                        >
                                            {button === '...' ? (
                                                <span className="page-link">...</span>
                                            ) : (
                                                <button
                                                    className="page-link"
                                                    onClick={() => handlePageChange(button)}
                                                >
                                                    {button}
                                                </button>
                                            )}
                                        </li>
                                    ))}
                                    {currentPage < totalPages && (
                                        <li className="page-item">
                                            <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
                                                Next
                                            </button>
                                        </li>
                                    )}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-12'>
                        {paginateItems().length === 0 ? (
                            <div className='no-data-message text-center pt-5'>
                                <h3>No data available</h3>
                            </div>
                        ) : (
                            paginateItems().map((item) => (
                                <div className='main-div-data  auction-data-system mb-3' key={item.id}
                                    style={{ cursor: 'pointer' }}>
                                    <h2 className='text-start auction-data-title'>
                                        {item.name}
                                    </h2>
                                    <p>
                                        <div className="bid-type">
                                            <i className="fa fa-rss" />
                                            {item?.bidding_type_id?.name}
                                        </div>
                                    </p>
                                    <div className='d-flex'>
                                        <div className='col-md-4 '>
                                            <div className='images-data '>
                                                <Magnifier className='ghsdfhhds overflow-hidden' src={item?.auction_images?.length > 0
                                                    ? `${process.env.REACT_APP_BASE_IMAGE_URL}${item?.auction_images[0]?.image_path}`
                                                    : DATA} alt='auction_images' />
                                                {/* <img
                                                    src={item?.auction_images?.length > 0
                                                        ? `${process.env.REACT_APP_BASE_IMAGE_URL}${item?.auction_images[0]?.image_path}`
                                                        : DATA}
                                                    alt='auction_images'
                                                /> */}
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className='davis-brother' style={{ wordWrap: "break-word", overflow: "hidden" }}>
                                                <h6>{item?.created_by?.username}</h6>
                                                <p>{item?.bidding_type_id?.name}</p>
                                                <p className='p-0'>{item?.location}</p>
                                                <p className='pt-1'>Date(s): {item?.start_date} - {item?.end_date}</p>
                                                <p>{item.description?.length > 80 ? (showDetails[item.id] ? item?.description : item.description.substring(0, 80) + "...") : item?.description}</p>
                                                {item?.description?.length > 100 && (
                                                    <p>
                                                        <button onClick={() => toggleDetails(item.id)} className='btn btn-link p-0'>
                                                            {showDetails[item.id] ? ' ▲' : ' ▼'}
                                                        </button>
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className='text-end'>
                                                <div className='flex flex-col' >
                                                    <button className='btn btn-primary d-flex items-center justify-center rounded prebidding-open-btn'>
                                                        <i className="far fa-flag"></i>
                                                        <p className=' m-0 mx-2 p-0 py-2' > Prebidding Open</p>
                                                    </button>
                                                </div>
                                                <div className='flex flex-col mt-3' >
                                                    <button className='btn btn-primary d-flex items-center justify-center rounded prebidding-open-btn ' style={{ background: '#dc3545', border: "1px solid" }}>
                                                        <i className="fa-solid fa-lock"></i>
                                                        <p className='m-0 mx-2 p-0 py-2'> {item?.bidding_type_id?.name}
                                                        </p>
                                                    </button>
                                                </div>
                                                <p className='mt-5 mx-3'>
                                                    <span><i className="fa-solid fa-truck"></i> &nbsp;</span>   {item.shipping_option?.is_shipping_available === 1
                                                        ? 'Shipping Available'
                                                        : 'Shipping Not Available'}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex' style={{ gap: "10px" }}>
                                        {/* {buttonsVisibility.auctionDetail && (
                                            <div>
                                                <button className='btn-auction'>
                                                    <span><i className="fa-solid fa-circle-info"></i> &nbsp;</span> Auction Detail
                                                </button>
                                            </div>
                                        )} */}
                                        {buttonsVisibility.viewCatalog && (
                                            <div>
                                                <button className='btn-catalog  view-catalog-btns' onClick={() => handleItemClick(item.hash_id)}>
                                                    <span><i className="fa-regular fa-note-sticky"></i></span> &nbsp; View Catalog
                                                </button>
                                            </div>
                                        )}
                                        {!isUserRegistered && buttonsVisibility.registerToBid && (
                                            <div>
                                                <button
                                                    className='btn-hammer '
                                                    onClick={handleRegisterClick}
                                                >
                                                    <span><i className="fa-solid fa-hammer"></i></span> &nbsp; Register To Bid
                                                </button>
                                            </div>
                                        )}
                                        {buttonsVisibility.bidLive && showLiveButton && (
                                            <div>
                                                <button className='btn-bid-live bid-live-btn'>
                                                    <span><i className="fa-solid fa-wifi"></i></span> &nbsp; Bid Live
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                    {
                                        item.warning && (
                                            <div className='refresh'>
                                                <div className='text-start'>
                                                    <h4 style={{ color: '#0d6efd' }}>WARNING</h4>
                                                    <p>{item.warning}</p>
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        item.bidding_notice && (
                                            <div className='refresh'>
                                                <div className='text-start'>
                                                    <h4 style={{ color: '#0d6efd' }}>BIDDING NOTICE:</h4>
                                                    <p>{item.bidding_notice}</p>
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        item.auctionNotice && (
                                            <div className='refresh'>
                                                <div className='text-start'>
                                                    <h4 style={{ color: '#0d6efd' }}>AUCTION NOTICE:</h4>
                                                    <p>{item.auctionNotice}</p>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            )))
                        }
                    </div>
                </div>
                <div className='col-md-12'>
                    <div className='d-flex justify-content-end'>
                        <nav aria-label="Page navigation">
                            <ul className="pagination">
                                {currentPage > 1 && (
                                    <li className="page-item">
                                        <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
                                            Previous
                                        </button>
                                    </li>
                                )}
                                {generatePaginationButtons().map((button, index) => (
                                    <li
                                        key={index}
                                        className={`page-item ${button === currentPage ? 'active' : ''}`}
                                    >
                                        {button === '...' ? (
                                            <span className="page-link">...</span>
                                        ) : (
                                            <button
                                                className="page-link"
                                                onClick={() => handlePageChange(button)}
                                            >
                                                {button}
                                            </button>
                                        )}
                                    </li>
                                ))}
                                {currentPage < totalPages && (
                                    <li className="page-item">
                                        <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
                                            Next
                                        </button>
                                    </li>
                                )}
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AuctionMainContent;
