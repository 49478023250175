// import React, { useState } from 'react';
// import { NavLink, useNavigate } from 'react-router-dom';
// import { useAuth } from '../../../AuthContext';
// import './Auctioner.css';
// import Avatar from 'react-avatar';

// const AuctionHeader = () => {
//     const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
//     const [isSidebarOpen, setIsSidebarOpen] = useState(false);
//     const username = localStorage.getItem('username');
//     const navigate = useNavigate();
//     const { logout } = useAuth();

//     const handleNavigateToProfile = () => {
//         navigate('/');
//     };

//     const handleLogout = () => {
//         logout();
//         navigate('/');
//     };

//     const handleNavigateToSignIn = () => {
//         navigate('/authentication/sign-in');
//     };

//     const toggleSubmenu = () => {
//         setIsSubmenuOpen(!isSubmenuOpen);
//     };

//     const toggleSidebar = () => {
//         setIsSidebarOpen(!isSidebarOpen);
//     };

//     return (
//         <div>
//             <button className="sidebar-toggle-btn" onClick={toggleSidebar}>
//                 {isSidebarOpen ? 'Close Sidebar' : 'Open Sidebar'}
//             </button>

//             <div className={`super-admin-sidebar ${isSidebarOpen ? 'open' : 'collapsed'}`}>
//                 {isSidebarOpen && (
//                     <button className="close-sidebar-btn" onClick={toggleSidebar}>X</button>
//                 )}

//                 <div className='sidebar-content p-0 py-4 px-1'>
//                     <div className='d-flex align-items-center mb-4'>
//                         <div className='text-start'>
//                             {username ? (
//                                 <p className='login-bidder text-uppercase p-0 px-5' onClick={handleNavigateToProfile}>
//                                     <Avatar
//                                         name={username}
//                                         size="30"
//                                         round
//                                         className='avatar'
//                                     />   <b>{username}</b>
//                                 </p>
//                             ) : (
//                                 <p className='login-bidder text-uppercase pb-3' onClick={handleNavigateToSignIn}>
//                                     <b style={{ color: "black" }}>Login / New Bidder</b>
//                                 </p>
//                             )}
//                         </div>
//                     </div>
//                     <nav className='sidebar-nav'>
//                         <ul className='nav flex-column'>
//                             <li className='nav-item px-2'>
//                                 <NavLink
//                                     to="/auctioner/dashboard"
//                                     className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}
//                                 >
//                                     Auctions
//                                 </NavLink>
//                             </li>
//                             <li className='nav-item px-2' onClick={handleLogout}>
//                                 <NavLink
//                                     to="/"
//                                     className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}
//                                 >
//                                     Logout
//                                 </NavLink>
//                             </li>
//                         </ul>
//                     </nav>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default AuctionHeader;
import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../AuthContext';
import './Auctioner.css';
import Avatar from 'react-avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

const AuctionHeader = () => {
    const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const username = localStorage.getItem('username');
    const navigate = useNavigate();
    const { logout } = useAuth();

    const handleNavigateToProfile = () => {
        navigate('/');
    };

    const handleLogout = () => {
        logout();
        navigate('/');
    };

    const handleNavigateToSignIn = () => {
        navigate('/authentication/sign-in');
    };

    const toggleSubmenu = () => {
        setIsSubmenuOpen(!isSubmenuOpen);
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <div className='h-full'>
            <button className="sidebar-toggle-btn" onClick={toggleSidebar}>
                <FontAwesomeIcon icon={isSidebarOpen ? faTimes : faBars} />
            </button>

            <div className={`super-admin-sidebar ${isSidebarOpen ? 'open' : 'collapsed'}`}>
                {isSidebarOpen && (
                    <button className="close-sidebar-btn" onClick={toggleSidebar}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                )}

                <div className='sidebar-content p-0 py-4 px-1'>
                    <div className='d-flex align-items-center mb-4'>
                        <div className='text-start'>
                            {username ? (
                                <p className='login-bidder text-uppercase p-0 px-5' onClick={handleNavigateToProfile}>
                                    <Avatar
                                        name={username}
                                        size="30"
                                        round
                                        className='avatar'
                                    />   <b>{username}</b>
                                </p>
                            ) : (
                                <p className='login-bidder text-uppercase pb-3' onClick={handleNavigateToSignIn}>
                                    <b style={{ color: "black" }}>Login / New Bidder</b>
                                </p>
                            )}
                        </div>
                    </div>
                    <nav className='sidebar-nav'>
                        <ul className='nav flex-column'>
                            <li className='nav-item px-2'>
                                <NavLink
                                    to="/auctioner/dashboard"
                                    className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}
                                >
                                    Auctions
                                </NavLink>
                            </li>
                            <li className='nav-item px-2' onClick={handleLogout}>
                                <NavLink
                                    to="/"
                                    className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}
                                >
                                    Logout
                                </NavLink>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    );
};

export default AuctionHeader;
