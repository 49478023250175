// import React, { useState, useEffect, useRef } from 'react';
// import axios from 'axios';
// import Echo from 'laravel-echo';
// import Pusher from 'pusher-js';
// import "./AuctionChat.css";
// import moment from 'moment';

// window.Pusher = Pusher;

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: 'your_pusher_key',
//     cluster: 'mt1',
//     wsHost: 'ws-mt1.pusher.com',
//     wsPort: 80,
//     wssPort: 443,
//     forceTLS: true,
//     enabledTransports: ['ws', 'wss'],
// });

// const Chat = () => {
//     const [messages, setMessages] = useState([]);
//     const [username, setUsername] = useState('');
//     const [message, setMessage] = useState('');
//     const [isChatVisible, setIsChatVisible] = useState(false);
//     const [isWebcamVisible, setIsWebcamVisible] = useState(true);
//     const chatContainerRef = useRef(null);
//     const videoRef = useRef(null);

//     const fetchMessages = () => {
//         axios
//             .get(`${process.env.REACT_APP_API_URL}/chat/messages`)
//             .then((response) => {
//                 setMessages(response.data);
//             })
//             .catch((error) => {
//                 console.error('Error fetching messages:', error);
//             });
//     };

//     useEffect(() => {
//         const storedUsername = localStorage.getItem('username');
//         if (storedUsername) {
//             setUsername(storedUsername);
//             setIsChatVisible(true);
//             fetchMessages();
//         }
//     }, []);

//     useEffect(() => {
//         if (isChatVisible) {
//             fetchMessages();
//             const channel = window.Echo.channel('message');
//             channel.listen('MessageEvent', (e) => {
//                 setMessages((prevMessages) => [...prevMessages, e.message]);
//             });
//             const intervalId = setInterval(fetchMessages, 5000);
//             return () => {
//                 window.Echo.leave('message');
//                 clearInterval(intervalId);
//             };
//         }
//     }, [isChatVisible]);

//     useEffect(() => {
//         if (chatContainerRef.current) {
//             chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
//         }
//     }, [messages]);

//     useEffect(() => {
//         if (isChatVisible && videoRef.current) {
//             navigator.mediaDevices
//                 .getUserMedia({
//                     video: { facingMode: { exact: "user" } },
//                 })
//                 .then((stream) => {
//                     videoRef.current.srcObject = stream;
//                 })
//                 .catch((error) => {
//                     console.error("Error accessing front camera:", error);
//                 });
//         }
//     }, [isChatVisible]);

//     const handleStartChat = (event) => {
//         event.preventDefault();
//         if (!username) {
//             alert('Please enter a username to start the chat.');
//             return;
//         }
//         localStorage.setItem('username', username);
//         setIsChatVisible(true);
//         fetchMessages();
//     };

//     const handleSendMessage = (event) => {
//         event.preventDefault();
//         axios
//             .post(`${process.env.REACT_APP_API_URL}/broadcast-message`, {
//                 username,
//                 message,
//             })
//             .then(() => {
//                 setMessage('');
//                 fetchMessages();
//             })
//             .catch((error) => {
//                 console.error('Error sending message:', error);
//                 if (error.response) {
//                     console.log('Error data:', error.response.data);
//                     console.log('Error status:', error.response.status);
//                 }
//             });
//     };

//     const handleCloseWebcam = () => {
//         setIsWebcamVisible(false);
//     };

//     return (
//         <div className='chat-message'>
//             {isWebcamVisible && (
//                 <div className="webcam-modal">
//                     <div className="webcam-modal-content">
//                         <video ref={videoRef} autoPlay className="webcam-feed" />
//                     </div>
//                 </div>
//             )}
//             {!isChatVisible ? (
//                 <div id="start-chat">
//                     <form onSubmit={handleStartChat}>
//                         <input
//                             type="text"
//                             value={username}
//                             onChange={(e) => setUsername(e.target.value)}
//                             placeholder="Enter your name"
//                             required
//                         />
//                         <input type="submit" value="Let's chat" />
//                     </form>
//                 </div>
//             ) : (
//                 <div id="chat-part" className='border rounded-xl'>
//                     <h2 className='text-center py-2'>Bidding Chats</h2>
//                     <div id="chat-container" ref={chatContainerRef}>
//                         {messages.map((msg, index) => (
//                             <>
//                                 <div key={index}>
//                                     <b>{msg.username} </b> &nbsp;

//                                     <small>{moment(msg.created_at).format('D MMM YYYY, HH:mm:ss A')}</small>
//                                 </div>
//                                 <div className='border rounded px-2 py-2 message-box relative'>
//                                     <span>{msg.message}</span>  &nbsp;
//                                 </div>
//                             </>

//                         ))}
//                     </div>

//                 </div>
//             )}
//         </div>
//     );
// };
// export default Chat;


import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import "./AuctionChat.css";
import moment from 'moment';
import Broadcaster from './Broadcaster';
import { useParams } from 'react-router-dom';

window.Pusher = Pusher;

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: 'your_pusher_key',
    cluster: 'mt1',
    wsHost: 'ws-mt1.pusher.com',
    wsPort: 80,
    wssPort: 443,
    forceTLS: true,
    enabledTransports: ['ws', 'wss'],
});

const Chat = (hashid) => {
    const [messages, setMessages] = useState([]);
    const [username, setUsername] = useState('');
    const [message, setMessage] = useState('');
    const [isChatVisible, setIsChatVisible] = useState(false);
    const [isWebcamVisible, setIsWebcamVisible] = useState(true);
    const [isMicMuted, setIsMicMuted] = useState(false);
    const chatContainerRef = useRef(null);
    const videoRef = useRef(null);
    const audioStreamRef = useRef(null);

    const currentUser = localStorage.getItem("username");
    //   const { hash_id } = useParams();

    const fetchMessages = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/chat/messages`)
            .then((response) => {
                setMessages(response.data);
            })
            .catch((error) => {
                console.error('Error fetching messages:', error);
            });
    };

    // console.log(hashid, "hashid")

    useEffect(() => {
        fetchMessages(hashid);
        console.log(hashid, "lot id")
    }, [hashid]);

    useEffect(() => {
        const storedUsername = localStorage.getItem('username');
        if (storedUsername) {
            setUsername(storedUsername);
            setIsChatVisible(true);
            fetchMessages();
        }
    }, []);

    // useEffect(() => {
    //     if (isChatVisible) {
    //         fetchMessages();
    //         const channel = window.Echo.channel('message');
    //         channel.listen('MessageEvent', (e) => {
    //             setMessages((prevMessages) => [...prevMessages, e.message]);
    //         });
    //         const intervalId = setInterval(fetchMessages, 5000);
    //         return () => {
    //             window.Echo.leave('message');
    //             clearInterval(intervalId);
    //         };
    //     }
    // }, [isChatVisible]);

    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [messages]);

    useEffect(() => {
        if (isChatVisible && videoRef.current) {
            navigator.mediaDevices
                .getUserMedia({
                    video: { facingMode: { exact: "user" } },
                    audio: true,
                })
                .then((stream) => {
                    videoRef.current.srcObject = stream;
                    audioStreamRef.current = stream;
                })
                .catch((error) => {
                    console.error("Error accessing front camera and microphone:", error);
                });
        }
    }, [isChatVisible]);

    const handleStartChat = (event) => {
        event.preventDefault();
        if (!username) {
            alert('Please enter a username to start the chat.');
            return;
        }
        localStorage.setItem('username', username);
        setIsChatVisible(true);
        fetchMessages();
    };

    const handleSendMessage = (event) => {
        event.preventDefault();
        axios
            .post(`${process.env.REACT_APP_API_URL}/broadcast-message`, {
                username,
                message,
            })
            .then(() => {
                setMessage('');
                fetchMessages();
            })
            .catch((error) => {
                console.error('Error sending message:', error);
                if (error.response) {
                    console.log('Error data:', error.response.data);
                    console.log('Error status:', error.response.status);
                }
            });
    };

    const handleCloseWebcam = () => {
        setIsWebcamVisible(false);
    };

    const toggleMic = () => {
        setIsMicMuted((prev) => {
            const newMutedState = !prev;
            if (audioStreamRef.current) {
                audioStreamRef.current.getTracks().forEach((track) => {
                    if (track.kind === 'audio') {
                        track.enabled = newMutedState;
                    }
                });
            }
            return newMutedState;
        });
    };

    return (
        <div className='chat-message'>
            <Broadcaster />
            {isWebcamVisible && (
                <div className="webcam-modal">
                    <div className="webcam-modal-content">
                        <video ref={videoRef} autoPlay className="webcam-feed" />
                    </div>
                </div>
            )}
            {!isChatVisible ? (
                <div id="start-chat">
                    <form onSubmit={handleStartChat}>
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder="Enter your name"
                            required
                        />
                        <input type="submit" value="Let's chat" />
                    </form>
                </div>
            ) : (
                <div id="chat-part" className='border rounded-xl'>
                    <h2 className='text-center py-2'>Bidding Chats</h2>
                    <div id="chat-container" className='relative' ref={chatContainerRef}>
                        {messages.map((msg, index) => (
                            <div
                                className={`message ${msg.username === currentUser ? "user" : "other"
                                    } border rounded px-2 py-2 w-50`}
                                key={index}
                            >
                                <div className="username">
                                    <b className="capitalize">{msg.username}</b>&nbsp;
                                    <small className="timestamp">
                                        {moment(msg.created_at).format(
                                            "D MMM YYYY, HH:mm:ss A"
                                        )}
                                    </small>
                                </div>
                                <div className="message-content">
                                    <span>{msg.message}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                    {/* <form onSubmit={handleSendMessage} className="px-[25px] py-[5px]">
                        <input
                            className="message-input"
                            type="text"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            placeholder="Enter message"
                            required
                        />
                        <input type="submit" value="Send" className="send-message" />
                    </form> */}
                    {/* <div id="chat-part">
                        <div id="chat-container" className='relative' ref={chatContainerRef}>
                            {messages
                                .filter((msg) => msg.lot_id === hash_id) // Ensure you're filtering by lot ID
                                .map((msg, index) => (
                                    <div
                                        className={`message ${msg.username === currentUser ? 'user' : 'other'} border rounded px-2 py-2 w-50`}
                                        key={index}
                                    >
                                    </div>
                                ))}
                        </div>
                        <form onSubmit={handleSendMessage} className='px-[25px] py-[5px]'>
                            <input
                                className='message-input'
                                type="text"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                placeholder="Enter message"
                                required
                            />
                            <input type="submit" value="Send" className='send-message' />
                        </form>
                    </div> */}
                    {/* <button onClick={toggleMic}>
                        {isMicMuted ? 'Unmute' : 'Mute'}
                    </button>
                    <form onSubmit={handleSendMessage}>
                        <input
                            type="text"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            placeholder="Type your message"
                            required
                        />
                        <input type="submit" value="Send" />
                    </form> */}
                </div>
            )}

        </div>
    );
};

export default Chat;
