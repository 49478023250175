import React, { useRef, useState } from 'react';
import Peer from 'simple-peer';
import axios from 'axios';
import { getPermissions } from './helpers';

const Broadcaster = ({ env }) => {
    const [isVisibleLink, setIsVisibleLink] = useState(false);
    const [streamingUsers, setStreamingUsers] = useState([]);
    const [allPeers, setAllPeers] = useState({});
    const broadcasterRef = useRef(null);

    // Get auth_user_id from local storage (hash_id)
    const auth_user_id = localStorage.getItem('hash_id');
    if (!auth_user_id) {
        return <div>Error: User ID not found</div>;
    }

    const streamId = `${auth_user_id}12acde2`;
    // console.log(streamId, "streamId")
    const streamLink =
        env === 'production'
            ? `https://auctionslive.net/streaming/${streamId}`
            : `http://localhost:3000/streaming/${streamId}`;

    const startStream = async () => {
        const stream = await getPermissions();
        broadcasterRef.current.srcObject = stream;
        initializeStreamingChannel(stream);
        initializeSignalAnswerChannel();
        setIsVisibleLink(true);
    };

    const peerCreator = (stream, user, signalCallback) => {
        let peer;
        return {
            create: () => {
                peer = new Peer({
                    initiator: true,
                    trickle: false,
                    stream: stream,
                });
            },
            getPeer: () => peer,
            initEvents: () => {
                peer.on('signal', (data) => {
                    signalCallback(data, user);
                });
                peer.on('stream', () => {
                    console.log('onStream');
                });
                peer.on('track', () => {
                    console.log('onTrack');
                });
                peer.on('connect', () => {
                    console.log('Broadcaster Peer connected');
                });
                peer.on('close', () => {
                    console.log('Broadcaster Peer closed');
                });
                peer.on('error', (err) => {
                    console.log('handle error gracefully');
                });
            },
        };
    };

    const initializeStreamingChannel = (stream) => {
        const channel = window.Echo.join(`streaming-channel.${streamId}`);

        channel.here((users) => {
            setStreamingUsers(users);
        });

        channel.joining((user) => {
            console.log('New User', user);
            if (!streamingUsers.some((u) => u.id === user.id)) {
                setStreamingUsers((prev) => [...prev, user]);
                const peer = peerCreator(stream, user, signalCallback);
                peer.create();
                peer.initEvents();

                setAllPeers((prev) => ({
                    ...prev,
                    [user.id]: peer,
                }));
            }
        });

        channel.leaving((user) => {
            console.log(user.name, 'Left');
            if (allPeers[user.id]) {
                allPeers[user.id].getPeer().destroy();
                delete allPeers[user.id];
                setAllPeers((prev) => {
                    const newPeers = { ...prev };
                    delete newPeers[user.id];
                    return newPeers;
                });
            }
            if (user.id === auth_user_id) {
                setStreamingUsers([]);
            } else {
                setStreamingUsers((prev) => prev.filter((u) => u.id !== user.id));
            }
        });
    };

    const initializeSignalAnswerChannel = () => {
        window.Echo.private(`stream-signal-channel.${auth_user_id}`).listen('StreamAnswer', ({ data }) => {
            console.log('Signal Answer from private channel');
            if (data.answer.sdp) {
                const updatedSignal = {
                    ...data.answer,
                    sdp: `${data.answer.sdp}\n`,
                };
                allPeers[data.receiver].getPeer().signal(updatedSignal);
            }
        });
    };

    const signalCallback = (offer, user) => {
        axios
            .post('https://auctionslive.net/stream-offer', {
                broadcaster: auth_user_id,
                receiver: user,
                offer,
            })
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-8 offset-md-2">
                    <button className="btn btn-success" onClick={startStream}>
                        Start Stream
                    </button>
                    <br />
                    {isVisibleLink && (
                        <p className="my-5">
                            Share the following streaming link: {streamLink}
                        </p>
                    )}
                    <video autoPlay ref={broadcasterRef}></video>
                </div>
            </div>
        </div>
    );
};

export default Broadcaster;
