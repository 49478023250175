import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import AuctionHeader from "./components/Auctioner/AuctionHeader/AuctionHeader";
import Home from "./components/pages/Home/Home";
import CompanySearch from "./components/pages/companysearch/CompanySearch";
import StateAuctions from "./components/pages/stateAuctions/StateAuctions";
import Category from "./components/pages/Category/MainCategory/Category";
import AboutHibid from "./components/pages/AboutHibid/AboutHibid";
import JoinTheHibid from "./components/pages/JoinTheHibid/JoinTheHibid";
import Help from "./components/pages/Help/Help";
import AuctionLogin from "./components/Auctioner/AuctionLogin/AuctionLogin";
import AuctionSignup from "./components/Auctioner/AuctionSignup/AuctionSignup";
import DashboardAuctioner from "./components/Auctioner/DashboardAuctioner/DashboardAuctioner";
import Tables from "./components/Auctioner/Tables/Tables";
import { AuthProvider, useAuth } from "./AuthContext";
import UserDetails from "./components/SuperAdmin/UserDetail/UserDetails";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import AdminCountryList from "./components/SuperAdmin/CountryList/AdminCountryList";
import EmailList from "./components/SuperAdmin/Email/EmailList";
import SetUpEmail from "./components/SuperAdmin/Email/SetUpEmail";
import SuperAdminHeader from "./components/SuperAdmin/Header/SuperAdminHeader";
import Dashboard from "./components/SuperAdmin/Dashboard/Dashboard";
import BiddingTypes from "./components/SuperAdmin/Bidding/BiddingTypes";
import Configuration from "./components/SuperAdmin/Configuration/Configuration";
import Categories from "./components/SuperAdmin/Categories/Categories";
import Subcategory from "./components/SuperAdmin/Categories/Subcategory";
import WatchList from "./components/Auctioner/WatchList/WatchList";
import Bids from "./components/Auctioner/Bids/Bids";
import AuctionCategories from "./components/Auctioner/auction-categories/AuctionCategories";
import Faq from "./components/pages/Faq/Faq";
import ContactUs from "./components/pages/Contact/ContactUs";
import ContactusForm from "./components/pages/Contact/ContactusForm";
import CompanySearchAuction from "./components/pages/CompanySearchAuction/CompanySearchAuction";
import LotsDetails from "./components/pages/LotsDetails/LotsDetails";
import AuctionDetail from "./components/pages/AuctionDetail/AuctionDetail";
import LotsDetailsLink from "./components/pages/LotsDetails/LotsDetailsLink";
import AuctionLots from "./components/Auctioner/Tables/AuctionLots";
import ProtectedRoute from "./components/ProtectdRoute/ProtectedRoute";

function App() {
  const { role } = useAuth();

  return (
    <Router>
      <div className="App">
        <ToastContainer />
        {role === "admin" ? (
          <Container fluid>
            <Row>
              <Col md={2} className=" m-0 p-0">
                <SuperAdminHeader />
              </Col>
              <Col md={10} className="p-0 m-0">
                <Routes>
                  <Route
                    path="/super/admin/dashboard"
                    element={
                     // <ProtectedRoute>
                        <Dashboard />
                      //</ProtectedRoute>
                    }
                  />
                  <Route
                    path="/super/admin/country-list"
                    element={
                      //<ProtectedRoute>
                        <AdminCountryList />
                      //</ProtectedRoute>
                      }
                  />
                  <Route
                    path="/admin/user-detail/:hash_id"
                    element={
                      //<ProtectedRoute>
                        <UserDetails />
                      //</ProtectedRoute>
                      }
                  />
                  <Route
                    path="/super/admin/email-setting"
                    element={
                    //<ProtectedRoute>
                      <SetUpEmail />
                    //</ProtectedRoute>
                    }
                  />
                  <Route
                    path="/super/admin/view-email-list"
                    element={
                     // <ProtectedRoute>
                        <EmailList />
                      //</ProtectedRoute>
                      }
                  />
                  <Route
                    path="/super/admin/bidding-types"
                    element={
                     // <ProtectedRoute>
                        <BiddingTypes />
                      //</ProtectedRoute>
                      }
                  />
                  <Route
                    path="/super/admin/configuration"
                    element={
                      //<ProtectedRoute>
                        <Configuration />
                      //</ProtectedRoute>
                      }
                  />
                  <Route
                    path="/super/admin/categories"
                    element={
                      //<ProtectedRoute>
                        <Categories />
                     // </ProtectedRoute>
                      }
                  />
                  <Route
                    path="/super/admin/categories/:hash_id"
                    element={
                     // <ProtectedRoute>
                        <Subcategory />
                      //</ProtectedRoute>
                      }
                  />
                  <Route path="/" element={
                    <Dashboard />
                  } />
                </Routes>
              </Col>
            </Row>
          </Container>
        ) : role === "auctioneer" ? (
          <>
            <Container fluid>
              <Row>
                <Col md={2} className=" m-0 p-0">
                  <AuctionHeader />
                </Col>
                <Col md={10} className="p-0 m-0">
                  <Routes>
                    <Route path="/" element={
                      <DashboardAuctioner />
                    } />
                    <Route
                      path="/auctioner/dashboard"
                      element={
                        <DashboardAuctioner />}
                    />
                    <Route path="/auctions/:id" element={
                      // <ProtectedRoute>
                        <Tables />
                       //</ProtectedRoute> 
                    } />

                    <Route path="/auction-lots-detail/:hash_id" element={
                      // <ProtectedRoute>
                        <AuctionLots />
                      //  </ProtectedRoute> 
                      } />

                    <Route
                      path="*"
                      element={
                        // <ProtectedRoute>
                          <Navigate to="/auctioner/dashboard" />
                        // </ProtectedRoute>
                        }
                    />
                  </Routes>
                </Col>
              </Row>
            </Container>
          </>
        ) : (
          <>
            <Header />
            <Routes>
              {role === "buyer" ? (
                <>
                  <Route path="/" element={
                    <Home />
                  } />
                  <Route path="/user/dashboard" element={
                    <Home />
                  } />
                  <Route path="/companysearch" element={
                    // <ProtectedRoute>
                      <CompanySearch />
                    // </ProtectedRoute>
                      } />
                  {/*<Route path="/company/:id" element={<CompanyDetail />} /> */}
                  <Route
                    path="/companysearch/:id"
                    element={
                      // <ProtectedRoute>
                        <CompanySearchAuction />
                      // </ProtectedRoute>
                      } 
                  />
                  <Route path="/stateauctions" element={<StateAuctions />} />
                  <Route path="/lots" element={
                    <Category />
                  } />
                  <Route path="/lots/:hash_id" element={
                    <LotsDetails />
                  } />
                  {/* <Route path="/lots/detail/:hash_id" element={<LotsDetailsLink />} /> */}

                  <Route
                    path="/auctions/:hash_id"
                    element={
                      <AuctionDetail />
                    }
                  />
                  <Route path="/info/about-us" element={                   
                      <AboutHibid />                   
                  } />
                  <Route path="/info/careers" element={                    
                      <JoinTheHibid />                   
                  } />
                  <Route path="/info/help" element={                   
                      <Help />                   
                  } />
                  <Route path="*" element={                   
                      <Navigate to="/user/dashboard" />                   
                  } />
                  <Route
                    path="/account/pastwatchlist"
                    element={
                      // <ProtectedRoute>
                        <WatchList />
                      //  </ProtectedRoute>
                      }
                  />
                  <Route path="/account/currentbids" element={
                    // <ProtectedRoute>
                    <Bids />
                  // </ProtectedRoute>
                  } /> 
                  <Route
                    path="/auction-categories"
                    element={                   
                      <AuctionCategories />
                    }
                  />
                  <Route path="/info/faq" element={                   
                    <Faq />                 
                  } />
                </>
              ) : (
                <>
                  <Route path="/" element={
                    <Home />
                  } />
                  <Route
                    path="/authentication/sign-in"
                    element={<AuctionLogin />}
                  />
                  <Route
                    path="/authentication/sign-up"
                    element={<AuctionSignup />}
                  />
                  <Route path="/companysearch" element={                    
                      <CompanySearch />
                   } />
                  <Route
                    path="/companysearch/:id"
                    element={                     
                        <CompanySearchAuction />
                    }
                  />
                  <Route path="/stateauctions" element={                    
                      <StateAuctions />
                   
                  } />
                  <Route path="/lots" element={
                    <Category />
                  } />
                  <Route path="/info/about-us" element={                   
                      <AboutHibid />
                   
                  } />
                  <Route path="/info/careers" element={                    
                      <JoinTheHibid />                    
                  } />
                  <Route path="/info/help" element={
                    <Help />
                  } />
                  <Route
                    path="/account/pastwatchlist"
                    element={
                      <WatchList />}
                  />
                  <Route path="/account/currentbids" element={
                    // <ProtectedRoute>
                      <Bids />
                  //  </ProtectedRoute>
                   } /> 
                  <Route
                    path="/auction-categories"
                    element={                     
                        <AuctionCategories />
                      }
                  />
                  <Route path="/lots/:hash_id" element={
                    <LotsDetails />
                  }
                  />
                  <Route
                    path="/auctions/:hash_id"
                    element={
                      <AuctionDetail />
                    }
                  />
                  <Route path="/info/faq" element={
                    <Faq />
                  } />
                  <Route path="/info/contact-us" element={
                    <ContactUs />
                  } />
                  <Route path="/home/sellstuff" element={
                    <ContactusForm />
                  } />
                </>
              )}
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </>
        )}
        {/* <Footer /> */}
        {role !== 'auctioneer' && role !== 'admin' && <Footer />}

      </div>
    </Router>
  );
}

export default function AppWrapper() {
  return (
    <AuthProvider>
      <App />
    </AuthProvider>
  );
}
