import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import moment from "moment";
import "./Chat.css";
// import Viewer from './Viewer';
import { useParams } from "react-router-dom";
window.Pusher = Pusher;

window.Echo = new Echo({
  broadcaster: "pusher",
  key: "your_pusher_key",
  cluster: "mt1",
  wsHost: "ws-mt1.pusher.com",
  wsPort: 80,
  wssPort: 443,
  forceTLS: true,
  enabledTransports: ["ws", "wss"],
});

const Chat = ({ hashId }) => {
  const [messages, setMessages] = useState([]);
  const [username, setUsername] = useState("");
  const [message, setMessage] = useState("");
  const [isChatVisible, setIsChatVisible] = useState(false);
  const [isWebcamVisible, setIsWebcamVisible] = useState(true);
  const [endDate, setEndDate] = useState();
  const chatContainerRef = useRef(null);
  const videoRef = useRef(null);
  const currentUser = localStorage.getItem("username");
  const { hash_id } = useParams();

  useEffect(() => {
    const fetchLotData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/lots/${hash_id}`
        );
        setEndDate(response.data.lots.auction.end_date);
        // console.log(response.data, "scscsdsdcsd")
      } catch (err) {
        //   setError(err);
      } finally {
        //   setLoading(false);
      }
    };
    fetchLotData();
  }, [hash_id]);

  const fetchMessages = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/chat/messages`)
      .then((response) => {
        setMessages(response.data);
        // console.log(response.data, "chat message");
      })
      .catch((error) => {
        console.error("Error fetching messages:", error);
      });
  };
  //    console.log(hash_id,"use params lotid")
  useEffect(() => {
    fetchMessages(hash_id);
    // console.log(hash_id,"lot id")
  }, [hash_id]);

  useEffect(() => {
    const storedUsername = localStorage.getItem("username");
    if (storedUsername) {
      setUsername(storedUsername);
      setIsChatVisible(true);
      fetchMessages();
    }
  }, []);

  const auctionEndDate = endDate;
  useEffect(() => {
    const checkAuctionTime = () => {
      const currentDate = new Date();
      const endDate = new Date(auctionEndDate);
      // console.log(endDate, "end date")
      const remainingTime = Math.ceil(
        (endDate - currentDate) / (1000 * 60 * 60 * 24)
      );
      // console.log(remainingTime, "days remaining");
      if (remainingTime === 7) {
        setIsWebcamVisible(false);
      }
    };
    checkAuctionTime();
    const interval = setInterval(checkAuctionTime, 86400000); // Check every 24 hours
    return () => clearInterval(interval); // Cleanup the interval on unmount
  }, [auctionEndDate]);

  useEffect(() => {
    if (isWebcamVisible && isChatVisible && videoRef.current) {
      // console.log(videoRef,"stream")
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          videoRef.current.srcObject = stream;
        })
        .catch((error) => {
          console.error("Error accessing webcam:", error);
        });
    }
  }, [isWebcamVisible, isChatVisible]);

  // useEffect(() => {
  //     if (isChatVisible) {
  //         fetchMessages();
  //         const channel = window.Echo.channel('message');
  //         channel.listen('MessageEvent', (e) => {
  //             // Whenever a message is received, add it to the messages array
  //             setMessages((prevMessages) => [...prevMessages, e.message]);
  //         });
  //         return () => {
  //             window.Echo.leave('message');
  //         };
  //     }
  // }, [isChatVisible]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  // useEffect(() => {
  //     if (isChatVisible && videoRef.current) {
  //         navigator.mediaDevices
  //             .getUserMedia({ video: true })
  //             .then((stream) => {
  //                 videoRef.current.srcObject = stream;
  //             })
  //             .catch((error) => {
  //                 console.error("Error accessing webcam:", error);
  //             });
  //     }
  // }, [isChatVisible]);

  const handleStartChat = (event) => {
    event.preventDefault();
    if (!username) {
      alert("Please enter a username to start the chat.");
      return;
    }
    // localStorage.setItem('username', username);
    setIsChatVisible(true);
    fetchMessages();
  };

  const handleSendMessage = (event) => {
    event.preventDefault();
    const timestamp = moment().format("YYYY-MM-DD HH:mm:ss");
    axios
      .post(`${process.env.REACT_APP_API_URL}/broadcast-message`, {
        username,
        message,
        lot_id: hashId,
        timestamp,
      })
      .then(() => {
        setMessage("");
        fetchMessages();
      })
      .catch((error) => {
        console.error("Error sending message:", error);
        if (error.response) {
          console.log("Error data:", error.response.data);
          console.log("Error status:", error.response.status);
        }
      });
  };

  // const handleCloseWebcam = () => {
  //     setIsWebcamVisible(false);
  // };

  return (
    <>
      <div className="chat-message">
        {/* <Viewer /> */}
        {isWebcamVisible && (
          <div className="webcam-modal">
            <div className="webcam-modal-content">
              <video ref={videoRef} autoPlay controls className="webcam-feed" />
            </div>
          </div>
        )}
        {!isChatVisible ? (
          <div id="start-chat">
            {/* <a href='/authentication/sign-in' className='text-decoration-none'>
                                <span className='text-primary font-weight-bold'>Please login</span>
                            </a> */}
          </div>
        ) : (
          <div id="chat-part">
            <div
              id="chat-container"
              className="relative"
              ref={chatContainerRef}
            >
              {messages
                .filter((msg) => msg.lot_id === hash_id) // Ensure you're filtering by lot ID
                .map((msg, index) => (
                  <div
                    className={`message ${msg.username === currentUser ? "user" : "other"
                      } border rounded px-2 py-2 w-50`}
                    key={index}
                  >
                    <div className="username">
                      <b className="capitalize">{msg.username}</b>&nbsp;
                      <small className="timestamp">
                        {moment(msg.created_at).format(
                          "D MMM YYYY, HH:mm:ss A"
                        )}
                      </small>
                    </div>
                    <div className="message-content">
                      <span>{msg.message}</span>
                    </div>
                  </div>
                ))}
            </div>
            <form onSubmit={handleSendMessage} className="px-[25px] py-[5px]">
              <input
                className="message-input"
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Enter message"
                required
              />
              <input type="submit" value="Send" className="send-message" />
            </form>
          </div>
        )}
      </div>
    </>
  );
};

export default Chat;
