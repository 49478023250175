import React, { useState, useEffect } from 'react';
import Lots from "../../../../assets/images/img.webp";
import JifImg from "../../../../assets/images/Woodburn1.png";
import axios from 'axios';
import "./Lots.css";
import LotLoader from '../Common/LotLoader';
import LotModal from './LotModal';
import { useLocation, useNavigate } from 'react-router-dom';
import Magnifier from "react-magnifier";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faTh, faThLarge } from '@fortawesome/free-solid-svg-icons';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.min.js';
import $ from 'jquery';
import Carousel from './Carousel';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment/moment';

const headers = {
    "ngrok-skip-browser-warning": "69420",
};

const MainDeivLots = ({ isSidebarVisible, isSinglePageVisible, onSinglePageToggle, lotSidebarState }) => {
    const [watchlist, setWatchlist] = useState({});
    const navigate = useNavigate();
    const [lensStyle, setLensStyle] = useState({ display: 'none' });
    const [currentPage, setCurrentPage] = useState(1);
    const [sortOption, setSortOption] = useState('bestMatch');
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [viewType, setViewType] = useState('list');
    const [lots, setLots] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedLot, setSelectedLot] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    let filterQuery = ""
    const location = useLocation();
    const fetchData = async () => {
        try {
            const accessToken = localStorage.getItem('access_token');
            if (!accessToken) {
                console.error('Access token not found in localStorage.');
                return;
            }

            const headers = {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${accessToken}`,
                'ngrok-skip-browser-warning': '69420',
            };

            const query = new URLSearchParams(location.search);
            const status = query.get('status');
            const hash_id = query.get('hash_id');
            const categoryHashId = query.get('category_hash_id');

            let response;
            if (categoryHashId) {
                response = await axios.get(`${process.env.REACT_APP_API_URL}/lots?category=${categoryHashId}`, { headers });
            } else if (hash_id) {
                response = await axios.get(`${process.env.REACT_APP_API_URL}/lots?state=${hash_id}`, { headers });
            } else if (query.has('auctionsnearme')) {
                response = await axios.get(`${process.env.REACT_APP_API_URL}/lots?nearmylocation=50`, { headers });
            } else if (status === 'CLOSING') {
                response = await axios.get(`${process.env.REACT_APP_API_URL}/lots?closing=true`, { headers });
            } else {
                response = await axios.get(`${process.env.REACT_APP_API_URL}/lots`, { headers });
            }
            setLots(response?.data?.data ?? []);
        } catch (error) {
            console.error('There was an error fetching the lots data:', error);
            setLots([]);
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        fetchData();
    }, [location.search]);

    const handleWatchlistToggle = async (e, item) => {
        e.stopPropagation();

        const isWatched = item?.watchlists?.length > 0 && item.watchlists[0].is_active === 1;

        const accessToken = localStorage.getItem('access_token');
        if (!accessToken) {
            console.error('Access token not found in localStorage.');
            return;
        }

        const headers = {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${accessToken}`,
            'ngrok-skip-browser-warning': '69420',
        };

        try {
            let response;
            if (isWatched) {
                response = await axios.get(`${process.env.REACT_APP_API_URL}/watchlist/remove/${item.hash_id}`, { headers });
            } else {
                response = await axios.get(`${process.env.REACT_APP_API_URL}/watchlist/add/${item.hash_id}`, { headers });
            }

            console.log(response.message, "API Response");

            if (response.data.status === 'success') {
                toast.success(response.data.message);
            } else {
                toast.error('An unexpected error occurred.');
            }
            fetchData();
        } catch (error) {
            console.error('Error updating watchlist:', error);
            toast.error('Error updating watchlist.');
        }
    };

    useEffect(() => {
        setIsLoading(true);
        if (lotSidebarState?.selectedAuctionStatus) {
            filterQuery += `status=${lotSidebarState.selectedAuctionStatus}`;
        }
        if (lotSidebarState?.selectedLotStatus) {
            filterQuery += `${filterQuery ? '&' : ''}filter=${lotSidebarState.selectedLotStatus}`;
        }
        if (lotSidebarState?.selectedCategory) {
            filterQuery += `${filterQuery ? '&' : ''}category=${lotSidebarState.selectedCategory}`;
        }
        if (lotSidebarState?.selectedCountry) {
            filterQuery += `${filterQuery ? '&' : ''}country=${lotSidebarState.selectedCountry}`;
        }
        if (lotSidebarState?.selectedCountryState) {
            filterQuery += `${filterQuery ? '&' : ''}countryState=${lotSidebarState.selectedCountryState}`;
        }
        if (lotSidebarState?.shippingAvailable !== "") {
            filterQuery += `${filterQuery ? '&' : ''}shippingAvailable=${lotSidebarState.shippingAvailable}`;
        }
        if (filterQuery) {
            axios.get(`${process.env.REACT_APP_API_URL}/lots?${filterQuery}`, { headers })
                .then(response => {
                    setLots(response?.data?.data ?? []);
                })
                .catch(error => {
                    console.error('There was an error fetching the lots data:', error);
                    setLots([]);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            setLots([]);
            setIsLoading(false);
        }
    }, [lotSidebarState]);

    const totalItems = lots.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const getItemsForCurrentPage = () => {
        if (isSinglePageVisible) {
            return lots;
        }
        const startIndex = (currentPage - 1) * itemsPerPage;
        return lots.slice(startIndex, startIndex + itemsPerPage);
    };
    const handleSortChange = (e) => setSortOption(e.target.value);
    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(Number(e.target.value));
        setCurrentPage(1);
    };
    const handleImageError = (e) => {
        e.target.src = JifImg;
    };


    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) setCurrentPage(page);
    };
    const handleViewChange = (view) => setViewType(view);
    const sortedItems = [...getItemsForCurrentPage()].sort((a, b) => {
        switch (sortOption) {
            case 'bestMatch':
                return a.id - b.id;
            case 'timeRemaining':
                return new Date(a.endDate) - new Date(b.endDate);
            case 'bidAmountHighToLow':
                return b.highBid - a.highBid;
            case 'bidAmountLowToHigh':
                return a.highBid - b.highBid;
            default:
                return a.id - b.id;
        }
    });

    const handleItemClick = (item) => {
        navigate(`/lots/${item.hash_id}`);
    };

    const generatePaginationButtons = () => {
        const buttons = [];
        const maxPageButtons = 5;
        if (totalPages <= maxPageButtons) {
            for (let i = 1; i <= totalPages; i++) buttons.push(i);
        } else {
            let startPage = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
            let endPage = Math.min(totalPages, currentPage + Math.floor(maxPageButtons / 2));
            if (currentPage - Math.floor(maxPageButtons / 2) < 1) endPage = Math.min(maxPageButtons, totalPages);
            if (currentPage + Math.floor(maxPageButtons / 2) > totalPages) startPage = Math.max(1, totalPages - maxPageButtons + 1);
            if (startPage > 1) {
                buttons.push(1);
                if (startPage > 2) buttons.push('...');
            }
            for (let i = startPage; i <= endPage; i++) {
                buttons.push(i);
            }
            if (endPage < totalPages) {
                if (endPage < totalPages - 1) buttons.push('...');
                buttons.push(totalPages);
            }
        }
        return buttons;
    };
    const openModal = (item) => {
        setSelectedLot(item);
        setIsModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedLot(null);
    };
    if (isLoading) {
        return <LotLoader />;
    }
    return (
        <div>
            <ToastContainer />
            <div className='row my-2 flex items-center justify-end'>

                <div className="flex items-center justify-end w-full ">
                    <button className={`view-button me-2 ${viewType === 'grid' ? 'active' : ''}`} onClick={() => handleViewChange('grid')}>
                        <FontAwesomeIcon icon={faList} />
                    </button>
                    <button className={`view-button me-2 ${viewType === 'largeGrid' ? 'active' : ''}`} onClick={() => handleViewChange('largeGrid')}>
                        <FontAwesomeIcon icon={faThLarge} />
                    </button>
                    <button className={`view-button ${viewType === 'list' ? 'active' : ''}`} onClick={() => handleViewChange('list')}>
                        <FontAwesomeIcon icon={faTh} />
                    </button>
                </div>


            </div>
            {!isSinglePageVisible && (
                <div className='row d-flex'>
                    <div className='col-md-2'>
                        <div className="form-group d-flex align-items-center flex-row">
                            <label htmlFor="itemsPerPageDropdown" className="me-2">Show:</label>
                            <select className="form-select" id="itemsPerPageDropdown" value={itemsPerPage} onChange={handleItemsPerPageChange}>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                    </div>
                    <div className='col-md-10 '>
                        {!isSinglePageVisible && (
                            <div className="d-flex justify-content-end">
                                <nav aria-label="Page navigation">
                                    <ul className="pagination">
                                        {currentPage > 1 && (
                                            <li className="page-item">
                                                <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
                                                    <i className="fas fa-chevron-left"></i>
                                                </button>
                                            </li>
                                        )}
                                        {generatePaginationButtons().map((button, index) => (
                                            <li
                                                key={index}
                                                className={`page-item ${button === currentPage ? 'active' : ''}`}
                                            >
                                                {button === '...' ? (
                                                    <span className="page-link">...</span>
                                                ) : (
                                                    <button
                                                        className="page-link"
                                                        onClick={() => handlePageChange(button)}
                                                    >
                                                        {button}
                                                    </button>
                                                )}
                                            </li>
                                        ))}
                                        {currentPage < totalPages && (
                                            <li className="page-item">
                                                <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
                                                    <i className="fas fa-chevron-right"></i>
                                                </button>
                                            </li>
                                        )}
                                    </ul>
                                </nav>
                            </div>
                        )}
                    </div>
                    <div className=''>
                        {!isSinglePageVisible && (
                            <p>Showing {(currentPage - 1) * itemsPerPage + 1} to {Math.min(currentPage * itemsPerPage, totalItems)} of {totalItems.toLocaleString()} lots</p>
                        )}
                    </div>
                </div>
            )}
            <div className='text-start'>
                FEATURED ON auctionslive.COM
            </div>
            <div className="row mb-3 pt-5">

                <Carousel items={sortedItems.slice(0, isSidebarVisible ? 2 : 3)} isSidebarVisible={isSidebarVisible} />
            </div>
            <div className={`row ${viewType === 'list' ? '' : 'd-flex'} gap-y-3`}>
                {lots?.length === 0 ? (
                    <div className="text-center">
                        <p>No data found</p>
                    </div>
                ) : (
                    viewType === 'largeGrid' ? (
                        lots?.map(item => (
                            <div className="col-md-4 mb-3 h-full" key={item.id} onClick={() => handleItemClick(item)} >
                                {/* VIEW2 */}
                                <div className="item border rounded-xl p-3 h-full">
                                    <div className='flex flex-col items-center justify-between h-full gap-2'>
                                        <p className='m-0 font-semibold text-md text-center'>{item.title}  </p>
                                        <div className='image-lots w-full h-full flex items-center justify-center min-h-[249px] min-w-[245px] max-w-[250px] max-h-[250px]'>
                                            <Magnifier className='w-full h-full object-cover ' src={
                                                item?.images[0]?.image_path
                                                    ? `${process.env.REACT_APP_BASE_IMAGE_URL}/${item?.images[0].image_path?.replace(/^\//, '')}`
                                                    : JifImg
                                            } />

                                        </div>
                                        <p className='font-semibold text-sm m-0'>{item?.description?.length > 30 ? item?.description?.substring(0, 30) + '...' : item?.description}</p>

                                        <div className='flex flex-col items-center justify-center gap-2'>
                                            <div className='text-center'>
                                                Estimate {item?.estimate_from} - {item?.estimate_to}
                                            </div>
                                            {moment(item?.auction?.end_date).isBefore(moment()) ? (
                                                <button className='btn btn-secondary w-full' disabled>
                                                    Closed
                                                </button>
                                            ) : (
                                                <button className='btn btn-primary w-full' onClick={() => openModal(item)}>
                                                    Bid <span>
                                                        {item?.highest_bidder?.amount
                                                            ? item.highest_bidder.amount
                                                            : item.auction.bid_increments[0].min_amount}
                                                    </span>
                                                </button>
                                            )}
                                            <LotModal
                                                isOpen={isModalOpen}
                                                lot={selectedLot}
                                                onClose={closeModal}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : viewType === 'grid' ? (
                        lots?.map(item => (
                            <div className='row' >
                                {/* VIEW1 */}
                                <li className={`item-list border rounded-xl ${item.id % 2 === 0 ? 'even' : 'odd'} flex items-center justify-between`} key={item.id} style={{ justifyContent: " center" }} >
                                    <div className='item-content flex items-center justify-between w-full '>
                                        <div className='image-lots w-full h-full flex items-center justify-center col-md-3' onClick={() => handleItemClick(item)}>
                                            <Magnifier className='w-full max-w-[200px] max-h-[150px] object-cover ' src={
                                                item?.images[0]?.image_path
                                                    ? `${process.env.REACT_APP_BASE_IMAGE_URL}/${item?.images[0].image_path?.replace(/^\//, '')}`
                                                    : JifImg
                                            } />

                                        </div>
                                        <div className='col-md-2 view-one-col-two-mobile' onClick={() => handleItemClick(item)}>
                                            <p className='font-semibold text-sm'>{item?.title}</p>
                                            <p className='font-semibold text-sm'> {item?.description?.length > 30 ? item?.description?.substring(0, 30) + '...' : item.description} </p>
                                        </div>
                                        <div className='col-md-1 view-one-col-three-mobile'>
                                            <p className='font-semibold text-sm m-0'> Total Bids : {item?.bids?.length || 0}</p>
                                        </div>
                                        <div className='col-md-2 view-one-col-three-mobile'>
                                            <p className='font-semibold text-sm m-0'>

                                                {(() => {
                                                    const timeLeft = new Date(item?.auction?.end_time) - new Date();
                                                    const daysLeft = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
                                                    const timeLeftDisplay = daysLeft > 0 ? `${daysLeft} days left` : 'Closed';
                                                    return timeLeftDisplay;
                                                })()}

                                            </p>
                                        </div>
                                        <div className='col-md-2'>
                                            {item?.highest_bidder?.status === "Winning" && (
                                                <p className='view-one-col-five-mobile'>
                                                    <b>High Bid : </b>{item.highest_bidder?.amount} USD
                                                </p>
                                            )}
                                        </div>
                                        <div className='col-md-2 flex items-center justify-center flex-col view-one-col-six-mobile'>
                                            <div className='font-semibold text-sm text-center'>
                                                Estimate : {item?.estimate_from} - {item?.estimate_to}
                                            </div>
                                            {moment(item?.auction?.end_date).isBefore(moment()) ? (
                                                <button className='btn btn-secondary w-full' disabled>
                                                    Closed
                                                </button>
                                            ) : (
                                                <button className='btn btn-primary w-full' onClick={() => openModal(item)}>
                                                    Bid <span>
                                                        {item?.highest_bidder?.amount
                                                            ? item.highest_bidder.amount
                                                            : item.auction.bid_increments[0].min_amount}
                                                    </span>
                                                </button>
                                            )}
                                            <LotModal
                                                isOpen={isModalOpen}
                                                lot={selectedLot}
                                                onClose={closeModal}
                                            />
                                            <div className='pt-3' onClick={() => handleItemClick(item)}>
                                                <p className='font-semibold text-sm' m-0> Shipping : <i className="fas fa-shipping-fast"></i></p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </div>
                        ))
                    ) : (
                        lots?.map(item => (
                            <div className={`col-md-${viewType === 'list' ? '12' : viewType === 'grid' ? '6' : '4'} mb-2`} key={item.id}>
                                {/* VIEW 3*/}
                                <div className={`cuckoo ${viewType} overflow-hidden border rounded-xl w-full h-full `}>
                                    <div className='clock'>
                                        <p className='schneider bg-gray-400 px-3 py-2 m-0' onClick={() => handleItemClick(item)}>
                                            <span>Lot {item?.lot_number} | {item?.title}</span>
                                        </p>
                                    </div>
                                    <div className='d-flex watch px-3 py-2 flex items-center justify-center view-three-mobile-box'>
                                        <div className='col-md-3 flex flex-col items-center justify-between view-three-col-one-mobile'>
                                            <div className='d-flex align-items-center gap-2 view-three-watch-mobile'>
                                                <i
                                                    className={`fa-star ${item?.watchlists?.length > 0 && item.watchlists[0].is_active === 1
                                                        ? 'fa fa-star text-warning'
                                                        : 'fa-regular fa-star'}`}
                                                    onClick={(e) => handleWatchlistToggle(e, item)}
                                                ></i>

                                                <p className='m-0'> Watch</p>
                                            </div>
                                            <div className='jif ' onClick={() => handleItemClick(item)}>
                                                <div className='magnifier-container flex items-center justify-center'>
                                                    <Magnifier className='w-full h-full object-cover ' src={
                                                        item?.images[0]?.image_path
                                                            ? `${process.env.REACT_APP_BASE_IMAGE_URL}/${item?.images[0].image_path?.replace(/^\//, '')}`
                                                            : JifImg
                                                    } />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-3 cuckoo-clock1' onClick={() => handleItemClick(item)}>
                                            <div className='text-start top'>
                                                <p className='m-0 text-base font-semibold view-three-des-mobile'>{item?.description?.length > 80 ? item?.description?.substring(0, 80) + '...' : item?.description}</p>
                                            </div>
                                        </div>
                                        <div className='col-md-3 bid-rates' onClick={() => handleItemClick(item)}>
                                            {item?.highest_bidder?.status === "Winning" && (
                                                <p className='m-0 text-base'>
                                                    High Bid: {item.highest_bidder?.amount} USD
                                                </p>
                                            )}
                                        </div>
                                        <div className='col-md-3 cuckoo-clock1 flex flex-col items-center justify-center gap-2 border-l border-gray-400 h-full'>
                                            <p className='realtors text-sm text-center m-0'><span>City :- {item?.auction?.city} &nbsp;<br /> Location :- {item?.auction?.location}</span></p>

                                            <div className='text-center text-sm'>
                                                Estimate {item?.estimate_from} - {item?.estimate_to}
                                            </div>
                                            {moment(item?.auction?.end_date).isBefore(moment()) ? (
                                                <button className='btn btn-secondary w-full' disabled>
                                                    Closed
                                                </button>
                                            ) : (
                                                <button className='btn btn-primary w-full' onClick={() => openModal(item)}>
                                                    Bid <span>
                                                        {item?.highest_bidder?.amount
                                                            ? item.highest_bidder.amount
                                                            : item.auction.bid_increments[0].min_amount}
                                                    </span>
                                                </button>
                                            )}

                                            <LotModal
                                                isOpen={isModalOpen}
                                                lot={selectedLot}
                                                onClose={closeModal}
                                            />
                                            <div className=''>
                                                <p className='m-0 text-md'>Shipping : <i className="fas fa-shipping-fast"></i></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    )
                )}
            </div>
            {
                !isSinglePageVisible && (
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className="d-flex justify-content-end mb-3">
                                <nav aria-label="Page navigation">
                                    <ul className="pagination">
                                        {currentPage > 1 && (
                                            <li className="page-item">
                                                <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
                                                    <i className="fas fa-chevron-left"></i>
                                                </button>
                                            </li>
                                        )}
                                        {generatePaginationButtons().map((button, index) => (
                                            <li
                                                key={index}
                                                className={`page-item ${button === currentPage ? 'active' : ''}`}
                                            >
                                                {button === '...' ? (
                                                    <span className="page-link">...</span>
                                                ) : (
                                                    <button
                                                        className="page-link"
                                                        onClick={() => handlePageChange(button)}
                                                    >
                                                        {button}
                                                    </button>
                                                )}
                                            </li>
                                        ))}
                                        {currentPage < totalPages && (
                                            <li className="page-item">
                                                <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
                                                    <i className="fas fa-chevron-right"></i>
                                                </button>
                                            </li>
                                        )}
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default MainDeivLots;
